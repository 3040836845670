import React from 'react';
import Modal from 'react-modal';
import { NotificationProvider } from './src/context/AddItemNotificationProvider';

// Set the app element
Modal.setAppElement('#___gatsby');

export const wrapRootElement = ({ element }) => (
  <NotificationProvider>{element}</NotificationProvider>
);
